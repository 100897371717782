<template>
  <apexchart
    ref="pieChart"
    :height="520"
    :type="chartType"
    :options="options"
    :series="series"
    v-if="show"
  >
  </apexchart>
</template>
<script>
export default {
  props: ["chartType", "chartOptions", "chartSeries", "refreshData","show"],
  name: "PieChart",
  data: () => ({
    refreshChart: false,
    series: [],
    options: {},
  }),
  mounted() {},
  created() {
    this.series = this.chartSeries;
    this.options = this.chartOptions;
  },
  methods: {},
  watch: {
  },
};
</script>

<style lang="scss">
.apexcharts-tooltip {
  background: white !important;
}
</style>