<template>
  <apexchart
    :width="chartWidth"
    :height="chartHeight"
    type="area"
    :options="chartOptions"
    :series="series"
    v-if="showChart"
  >
  </apexchart>
</template>
<script>
export default {
  props: [
    "chartType",
    "chartWidth",
    "chartHeight",
    "tier",
    "color",
    "chartData",
    "labels",
    "chartOptions",
    "show",
  ],
  name: "ChartLine",
  data: () => ({
    showChart: false,
    series: [],
    options: {
      xaxis: {},
      chart: {
        height: 350,
        type: "area",
      },
    },
  }),
  mounted() {
    this.series.push(this.chartData);
    this.options.xaxis = this.chartOptions.xaxis;
    this.showChart = this.show;
    console.log("series=>", this.chartData);
    console.log("options=>", this.options);
  },
};
</script>

<style lang="scss">
.apexcharts-tooltip {
  background: white !important;
}
</style>