<template>
  <div id="selected-tier-processing">
    <v-row>
      <v-col cols="12" sm="12" class="pa-1">
        <div class="display-1">
          Segments Preview
          <v-btn
            @click="setStepper()"
            class="float-right btns color-white"
            color="#109CF1"
            elevation="0"
            >Next</v-btn
          >
          <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#109CF1"
            elevation="0"
            >Back</v-btn
          >
          <v-menu
            v-model="filter"
            :close-on-content-click="true"
            offset-y
            class="float-right"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                :color="getTierColor"
                class="
                  float-right
                  mr-8
                  btns
                  color-white
                  black--text
                  text-capitalize
                  shadow-none
                "
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-filter-outline</v-icon>
                {{ tier ? tier : "Filter" }}
              </v-btn>
            </template>

            <v-card>
              <v-list class="pa-0">
                <v-radio-group
                  v-model="tier"
                  :mandatory="false"
                  class="ma-0 pa-0"
                  hide-details=""
                >
                  <v-list-item
                    class="h-12"
                    v-for="(item, index) in tiers"
                    :key="index"
                  >
                    <v-list-item-action>
                      <v-radio
                        :color="item.color"
                        @click="Filter(item.title)"
                        :value="item.title"
                      ></v-radio>
                    </v-list-item-action>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-radio-group>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading.pie && loading.spent && loading.table">
      <v-col cols="12" sm="12" class="h-screen">
        <v-progress-circular
          id="sharethis-loading"
          :size="50"
          color="#94D227"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="my-5">
        <v-col cols="6" sm="6" class="py-8 pr-4 pl-2">
          <v-card class="mx-auto py-10 px-3 h-full">
            <div
              class="
                display-6
                font-weight-bold
                mb-4
                flex
                justify-between
                items-center
              " style="color:#109CF1"
            >
              Categories
            </div>
            <div class="pie">
              <PieChart
                :show="PieData.show"
                :key="forceRender"
                :chartSeries="PieData.series"
                :chartOptions="PieData.options"
                chartType="pie"
              />
            </div>
          </v-card>
        </v-col>
        <v-col cols="6" sm="6" class="py-8 pl-4 pr-0">
          <v-card class="mx-auto py-10 px-3 h-full">
            <div
              class="
                display-6
                font-weight-bold
                mb-4
                flex
                justify-between
                items-center
                select-date
              " style="color:#109CF1"
            >
              Total Spend

              <v-menu
                class="float-right"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-x
                left
                min-width="auto"
                disabled
              >
                <template
                  class="max-w-xs float-right"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-text-field
                    outlined
                    class="select-month float-right"
                    v-model="dateformater"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details=""
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" type="month" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="selectedMonth()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
            <div class="line mt-10">
              <SpendChart
                chartHeight="370"
                :chartOptions="LineChart.options"
                :key="forceRender"
                :chartData="LineChart.dataset"
                :labels="LineChart.labels"
                :show="LineChart.show"
              />
              <div class="spent ma-4">
                <div class="caption">Total Spent</div>
                <div class="text-xl font-weight-bold">
                  {{ spent.total_spent }}
                </div>
                <div class="caption">
                  <v-icon size="18" class="w-2" color="black"
                    >mdi-arrow-up-thin</v-icon
                  >
                  {{ spent.percentage }}%
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" class="py-4 pl-2 pr-0">
          <v-card class="mx-auto py-10 px-6 h-full">
            <h2
              class="
              capitalize
                font-weight-bold
                mb-4
                flex
                justify-between
                items-center
                select-date
              "
            >
              Select mapping option
            </h2>
            <div class="mapping_option_values_data flex justify-between">
              <v-radio-group v-model="mapping.first" class="mapping" col v-if="mappingItems[0].mapping_option_values.length">
                <h3 class="font-weight-bold mb-2">{{ mappingItems[0].mapping_option_name}}</h3>
                <v-radio
                  v-for="(item, index) in mappingItems[0].mapping_option_values"
                  :key="index"
                  :label="item"
                  :value="item"
                  class="mr-10"
                ></v-radio>
              </v-radio-group>
              <v-radio-group v-model="mapping.second" class="mapping" col v-if="mappingItems[1].mapping_option_values.length">
                <h3 class="font-weight-bold mb-2">{{ mappingItems[1].mapping_option_name}}</h3>
                <v-radio
                  v-for="(item, index) in mappingItems[1].mapping_option_values"
                  :key="index"
                  :label="item"
                  :value="item"
                  class="mr-10"
                ></v-radio>
              </v-radio-group>
              <v-radio-group v-model="mapping.third" class="mapping" col v-if="mappingItems[2].mapping_option_values.length">
                <h3 class="font-weight-bold mb-2">{{ mappingItems[2].mapping_option_name}}</h3>
                <v-radio
                  v-for="(item, index) in mappingItems[2].mapping_option_values"
                  :key="index"
                  :label="item"
                  :value="item"
                  class="mr-10"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-5" id="high-value">
        <v-col cols="12" sm="12" class="mb-4">
          <div class="display-6 font-weight-bold">Tier Values</div>
        </v-col>
        <v-col cols="12" sm="12">
          <v-data-table
            show-expand
            single-expand
            show-select
            item-key="CustomerID"
            v-model="selected"
            :single-select="singleSelect"
            :headers="table__header"
            :items="table__data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            @item-expanded="loadCats"
          >
            >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="expand-item pa-5">
                <div
                  class="
                    expand-item-title
                    text-center
                    center
                    mb-5
                    font-weight-bold
                  "
                >
                  All Categories
                </div>
                <div class="grid grid-cols-6 expand-item-cats">
                  <span v-for="(cat, i) in item.cats" :key="i">
                    {{ cat }}
                  </span>
                </div>
              </td>
            </template>
            <template v-slot:[`item.tier`]="{ item }">
              <v-chip color="#f88787" dark v-if="item.tier == 'low'">
                {{ item.tier }}
              </v-chip>
              <v-chip color="#ffe380" dark v-else-if="item.tier == 'medium'">
                {{ item.tier }}
              </v-chip>
              <v-chip color="#87f8b4" dark v-else>
                {{ item.tier }}
              </v-chip>
            </template>
          </v-data-table>

          <v-pagination
            id="high-value-pagination"
            :total-visible="7"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import SpendChart from "./Charts/SpendChart.vue";
import PieChart from "./Charts/PieChart.vue";
import moment from "moment";
export default {
  components: { PieChart, SpendChart },
  data() {
    return {
      loading: {
        pie: true,
        spent: true,
        table: true,
      },
      mapping:{
        first:[],
        second:[],
        third:[]
      },
      mappingItems: [
          {
            "mapping_option_name": "Partner ID",
            "mapping_option_values": [
              "Hashed Email (87%)",
              "TradeDesk Universal ID (78%)",
              "LiveRamp (69%)",
              "ID5 (59%)",
              "MAID (54%)"
            ]
          },
          {
            "mapping_option_name": "Geo",
            "mapping_option_values": [
              "County (45MM)",
              "DMA (38MM)",
              "Postal Code (32MM)"
            ]
          },
          {
            "mapping_option_name": "Contextual",
            "mapping_option_values": [
              "Contextual Match"
            ]
          }
      ],
      spent: { total_spent: 100657.67, percentage: 45.78 },
      forceRender: 1,
      tier: this.$store.getters.getSegBtn,
      tiers: [
        { color: "#87f8b4", title: "high" },
        { color: "#ffe380", title: "medium" },
        { color: "#f88787", title: "low" },
      ],
      date: new Date(2021, 6, 10).toISOString().substr(0, 7),
      crm_seg: "",
      singleSelect: false,
      selected: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      table__header: [],
      table__data: [],
      LineChart: {
        options:{},
        dataset: {
          color: "",
          name: "",
          data: []
        },
        labels: [],
        show:false
      },
      PieData: {
        show:false,
        options: [],
        series: [],
      },
      filter: false,
      menu: false,
      segData: [],
      segLabels: [],
      top_cats_data: {},
      all_categories_summary_data: [],
      approchName: this.$store.getters.getApproch,
      tierName: this.$store.getters.getSegBtn,
      params: this.$store.getters.getParams,
    };
  },
  mounted() {
    this.setApprochData();
  },
  methods: {
    setApprochData() {
      this.mapping_option_values();
      this.selectTier();
      this._top_cats_data();
      this._all_categories_summary_data();
      this._spend_data();
      this._total_spent();
      this.format__data();
    },
    selectTier() {
      this.LineChart.dataset.color = this.tiers.find(
        (item) => item.title == this.$store.getters.getSegBtn
      ).color;
      this.LineChart.dataset.name = this.$store.getters.getSegBtn;
    },
    Filter(tier) {
      this.tierName = tier.toLowerCase();
      this.$store.commit("setSegBtn", this.tierName);
      this.setApprochData();
    },
    loadCats({ item }) {
      console.log('item==>',item); 
      return (item.cats = this.all_categories_summary_data.find(
        (x) => x["CustomerID"] == item["CustomerID"]
      )[this.params['category_column']+'s']);
    },
    format__data(params) {
      this.table__header = [];
      this.table__data = [];
      var data__table = [];
      data__table = this.all_categories_summary_data.filter(
        (el) => el.tier == this.$store.getters.getSegBtn
      );
      console.log("data__table===>", data__table[0]);
      if (data__table.length) {
        Object.keys(data__table[0]).forEach((el) => {
          if (el != "index") this.table__header.push({ text: el, value: el });
        });
        data__table.forEach((el) => {
          el[params['category_column']+'s'] = el[params['category_column']];
          el[params['category_column']] = el[params['category_column']][0];
          el["ShareThisCategories"] = el["ShareThisCategories"][0];
          this.table__data.push(el);
        });
      }
      this.loading = {
        pie: false,
        spent: false,
        table: false,
      };
    },
    setStepper() {
      this.$store.commit("setStepper", { type: "crm", val: 3 });
      this.$router.push({ path: "/categories-insights" });
    },
    selectedMonth() {},
    async _top_cats_data() {
      await this.$http
        .post(
          "/crm_clusters_top_categories?clustering_technique=" +
            this.approchName.toLowerCase() +
            "&tier=" +
            this.tierName.toLowerCase()
        )
        .then((res) => {
          console.log(
            "--crm_clusters_top_categories?clustering_technique=" +
              this.approchName.toLowerCase() +
              "&tier=" +
              this.tierName.toLowerCase(),
            res.data
          );
          this.PieData.series =res.data.series;
          this.PieData.options =res.data.options;
          this.PieData.show = true;
          this.forceRender++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async _all_categories_summary_data() {
      await this.$http
        .post("/crm_clusters_view?clustering_technique=" + this.approchName)
        .then((res) => {
          console.log(
            "--all_categories_" + this.approchName + "/summary_all--->",
            res.data.data
          );
          this.all_categories_summary_data = res.data.data;
          this.selectTier();
          this.format__data(res.data.params);
          this.$store.commit('setParams',res.data.params)
          this.forceRender++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async mapping_option_values() {
      await this.$http
        .post("/mapping_option_values")
        .then((res) => {
          console.log("--mapping_option_values", res.data);
          this.mappingItems = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async _spend_data() {
      await this.$http
        .post(
          "/crm_clusters_tier_spend?clustering_technique=" +
            this.approchName +
            "&tier=" +
            this.tierName.toLowerCase()
        )
        .then((res) => {
          console.log("--" + this.tierName + "__spend--->", res.data); 
          let categories=res.data.options.xaxis.categories
          let index=categories[categories.length-1] ? categories[categories.length-1] : categories.length-1
          this.LineChart.dataset.data = res.data.series[0].data.slice(0, index);
          this.LineChart.labels = categories;
          this.LineChart.options = res.data.options;
          this.LineChart.show = true;
          this.forceRender++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async _total_spent() {
      await this.$http
        .post(
          "/crm_spend_value?clustering_technique=" +
            this.approchName +
            "&tier=" +
            this.tierName.toLowerCase()
        )
        .then((res) => {
          console.log("--total___spend--->", res.data);
          this.spent=res.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    getTierColor() {
      return this.tiers.find(
        (tier) => tier.title == this.$store.getters.getSegBtn
      ).color;
    },
    dateformater() {
      return moment(this.date).format("MMM YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>
html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
#selected-tier-processing::v-deep {
  .pie .apexcharts-legend {
    display: flex;
    justify-content: space-between;
  }
  .pie .apexcharts-legend.apx-legend-position-left::-webkit-scrollbar {
    width: 11px;
  }
  .pie .apexcharts-legend.apx-legend-position-left {
    scrollbar-width: thin;
  }
  .pie .apexcharts-legend.apx-legend-position-left::-webkit-scrollbar-track {
    border: 1px solid #109cf13d;
    border-radius: 5px;
  }
  .pie .apexcharts-legend.apx-legend-position-left::-webkit-scrollbar-thumb {
    background-color: #109cf1;
    border-radius: 6px;
    border: 3px solid #109cf1;
  }
  .v-data-table-header {
    background: #009fff42 !important;
  }
  #high-value-pagination
    .theme--light.v-pagination
    .v-pagination__item--active {
    background-color: #ffffff !important;
    border-color: #109cf1 !important;
    color: #109cf1 !important;
  }
  #high-value-pagination button {
    box-shadow: none !important;
    border: 1px solid rgb(172 174 178) !important;
  }
  #high-value-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"])
    *,
  #high-value-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"])
    * {
    color: white;
  }
  #high-value-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"]),
  #high-value-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"]) {
    background: #109cf1;
  }
  #high-value-pagination {
    position: absolute !important;
    top: 0;
    right: 0;
  }
  #high-value {
    position: relative !important;
  }
  .select-month {
    width: 30% !important;
    max-width: 30% !important;
  }
  .select-month div {
    margin: 0 !important;
    height: 36px;
    min-height: 36px;
  }
}
</style>
 